/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'default_team_icon': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<g _fill="none" fill-rule="evenodd"><path pid="0" _fill="#F4EBD9" d="M0 0h18v18H0z"/><path pid="1" _fill="#154F4A" fill-rule="nonzero" d="M15.5 2.5v13h-13v-13h13zm-1 6.5h-11v5.5h1v-2a2 2 0 114 0v2h1v-2a2 2 0 114 0v2h1V9zm-8 2.5a1 1 0 00-1 1v2h2v-2a1 1 0 00-1-1zm5 0a1 1 0 00-1 1v2h2v-2a1 1 0 00-1-1zm3-8h-11V8h11V3.5zM6.25 5v1.25H5V5h1.25zM8.5 5v1.25H7.25V5H8.5zm2.25 0v1.25H9.5V5h1.25zM13 5v1.25h-1.25V5H13z"/></g>'
  }
})
